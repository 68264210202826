<template>
  <div
    class="login_box"
    @click="selectShow = false"
    v-loading="$store.state.metaShow"
    :element-loading-text="$store.state.loginText"
  >
    <el-row type="flex" class="login">
      <el-col :xs="24" :sm="12" class="login_left">
        <h2>VisionMoney</h2>
      </el-col>
      <el-col :xs="24" :sm="12" class="login_right">
        <div class="login_content" v-if="signType === 1">
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: flex-end;
              margin-bottom: 10px;
            "
          >
            <div style="width: 100px">
              <chosse-net />
            </div>
          </div>
          <div class="sign">
            <h2
              @click="loginType = 'email'"
              :class="loginType === 'email' ? 'blod' : ''"
              style="font-size: 20px"
            >
              <!-- 邮箱注册 -->
              {{ $t("emailSign") }}
            </h2>
            <h2
              @click="loginType = 'phone'"
              :class="loginType === 'phone' ? 'blod' : ''"
              style="font-size: 20px"
            >
              <!-- 手机注册 -->
              {{ $t("phoneSign") }}
            </h2>
          </div>
          <div class="countries" v-if="loginType === 'phone'">
            <vue-country-intl
              schema="popover"
              @onChange="handleCountryChange"
              search-input-placeholder=""
            >
              <div class="phoneCode" slot="reference">
                <div>
                  <!-- 选择国家 -->
                  {{ $t("selectCountry") }}
                  : +{{ phoneCode.dialCode }}
                  <span :class="'iti-flag ' + phoneCode.code"> </span>
                </div>
                <i class="iconfont icon-xia"> </i></div
            ></vue-country-intl>
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.phone.rules === 3,
              borderY: Phonefrom.phone.rules === 2,
            }"
          >
            <input
              type="text"
              :placeholder="
                loginType === 'phone' ? $t('inputPhone') : $t('inputEmail')
              "
              v-model="Phonefrom.phone.value"
              :class="{ red: Phonefrom.phone.rules === 3 }"
              @keyup.enter="subPhone"
              @focus="getfocus('phone')"
              @blur="getBlur(loginType)"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.name.rules === 3,
              borderY: Phonefrom.name.rules === 2,
            }"
          >
            <input
              type="text"
              :placeholder="$t('inputUserName')"
              v-model="Phonefrom.name.value"
              @keyup.enter="subPhone"
              :class="{ red: Phonefrom.name.rules === 3 }"
              @focus="getfocus('name')"
              @blur="getBlur('name')"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.yzm.rules === 3 && YZMBtn,
              borderY: Phonefrom.yzm.rules === 2,
            }"
          >
            <input
              type="text"
              class="yzm"
              :placeholder="$t('inputCode')"
              v-model="Phonefrom.yzm.value"
              @keyup.enter="subPhone"
              maxlength="6"
              :class="{ red: Phonefrom.yzm.rules === 3 }"
            />
            <button
              :class="Phonefrom.phone.value ? 'yzmShow' : ''"
              :disabled="!Phonefrom.phone.value || YZMBtn"
              @click="getyzm()"
            >
              {{ !YZMBtn ? $t("codeTips") : yzmText }}
            </button>
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.picyzm.rules === 3,
              borderY: Phonefrom.picyzm.rules === 2,
            }"
          >
            <input
              type="text"
              :class="{ red: Phonefrom.picyzm.rules === 3 }"
              :placeholder="$t('inputPicCode')"
              v-model="Phonefrom.picyzm.value"
              @keyup.enter="subPhone"
            />
            <img :src="picCode" class="picyzm" @click="getPicCode" />
          </div>
          <div class="input_box">
            <input
              type="text"
              :placeholder="$t('invitationCode')"
              v-model="invitationCode"
              @keyup.enter="subPhone"
            />
          </div>
          <div class="type_button">
            <span @click="gotoUrl('login')">
              <!-- 账号登录 -->
              {{ $t("accountLogin1") }}
            </span>
          </div>
          <button
            class="login_button"
            @click="subPhone"
            :class="{
              yz_button:
                YZMBtn &&
                Phonefrom.phone.value &&
                Phonefrom.yzm.value &&
                Phonefrom.name.value,
            }"
            :disabled="subPhoneShow"
            v-loading="subPhoneLoad"
          >
            <!-- 下一步 -->
            {{ $t("next") }}
          </button>
        </div>
        <div class="login_content" v-if="signType === 2">
          <h2>
            <!-- 设置密码 -->
            {{ $t("settingPassWordTips") }}
          </h2>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password.rules === 3,
              borderY: Phonefrom.password.rules === 2,
            }"
          >
            <input
              :type="passwordEye ? 'text' : 'password'"
              :placeholder="$t('settingPassWord')"
              v-model="Phonefrom.password.value"
              :class="{ red: Phonefrom.password.rules === 3 }"
              @focus="getfocus('password')"
              @blur="getBlur('password')"
              @change="checkPasswordLength()"
            />
            <img
              v-if="!passwordEye"
              src="../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="passwordEye = !passwordEye"
            />
            <img
              v-if="passwordEye"
              src="../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="passwordEye = !passwordEye"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password2.rules === 3,
              borderY: Phonefrom.password2.rules === 2,
            }"
          >
            <input
              :type="password2Eye ? 'text' : 'password'"
              :placeholder="$t('settingPassWord2')"
              v-model="Phonefrom.password2.value"
              :class="{ red: Phonefrom.password2.rules === 3 }"
              @blur="
                getBlur('password2');
                checkPasswordLength();
              "
              @focus="getfocus('password2')"
            />
            <img
              v-if="!password2Eye"
              src="../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="password2Eye = !password2Eye"
            />
            <img
              v-if="password2Eye"
              src="../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="password2Eye = !password2Eye"
            />
          </div>
          <h6 v-if="passwordShow">
            <!-- 两次输入密码不相符 -->
            {{ $t("passwordError") }}
          </h6>
          <h6 v-if="passwordLengthShow">
            <!-- 密码长度不够提示 -->
            {{ $t("passwordLenthTip") }}
          </h6>
          <h4>
            <!-- 密码提示 -->
            {{ $t("passwordTips") }}
          </h4>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.passwordTips.rules === 3,
              borderY: Phonefrom.passwordTips.rules === 2,
            }"
          >
            <input
              type="text"
              :class="{ red: Phonefrom.passwordTips.rules === 3 }"
              :placeholder="$t('passwordTips2')"
              v-model="Phonefrom.passwordTips.value"
              @focus="getfocus('passwordTips')"
              @blur="getBlur('passwordTips')"
              @keyup.enter="debounceLogin()"
            />
          </div>
          <button
            class="login_button2"
            @click="debounceLogin()"
            :class="{
              yz_button:
                Phonefrom.password.value &&
                Phonefrom.password2.value &&
                !passwordShow &&
                !passwordLengthShow,
            }"
            :disabled="subPasswordShow"
            v-loading="subPasswordLoad"
          >
            <!-- 注册 -->
            {{ $t("sginUp2") }}
          </button>
          <p
            @click="
              signType = 1;
              subPhoneLoad = false;
            "
          >
            <!-- 上一步 -->
            {{ $t("previousStep") }}
          </p>
        </div>
      </el-col>
    </el-row>
    <!-- <el-dialog
      title="MetaID Creation"
      class="user_home"
      :visible.sync="metaTipt"
      >
      <span>{{$t('metaTxt')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button >{{$t('quit')}}</el-button>
        <el-button type="primary" @click="createMeta()">
          {{$t('Restart')}}
        </el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import countries from "@/common/js/countryList";
import {
  registerVerification,
  registerCheck,
  getImgCode,
  getReferrerInfo,
} from "@/api/api";
import { isVerifiedPhone, isVerifiedEmail } from "@/common/js/verification";
import { useTestNet } from "../common/js/baseApi";
import chosseNet from "../components/chosseNet.vue";

export default {
  components: {
    "chosse-net": chosseNet,
  },

  data() {
    return {
      passwordEye: false,
      password2Eye: false,
      passwordLengthShow: false,
      passWordType: false,
      passWordType2: false,
      invitationCode: localStorage.getItem("referCode") || "",
      referrerId: null,
      metaTipt: true,
      metanetSdk: null,
      countries: countries,
      phoneCode: {
        code: "us",
        name: "United States",
        dialCode: 1,
        phoneFormat: "131 2345 6789",
      },
      loginType: "email",
      signType: 1,
      codeimg: "cn",
      selectShow: false,
      subPhoneShow: false,
      cert: "",
      subPhoneLoad: false,
      subPasswordShow: false,
      subPasswordLoad: false,
      passwordShow: false,
      yzmText: this.$t("codeTips"),
      yzmType: false,
      yzmLoading: false,
      did: "",
      pk2: "",
      YZMBtn: false,
      userData: "",
      timeNub: 60,
      timeC: "",
      picCode: "",
      Phonefrom: {
        phone: {
          rules: 1,
          value: "",
        },
        email: {
          rules: 1,
          value: "",
        },
        yzm: {
          rules: 1,
          value: "",
        },
        picyzm: {
          rules: 1,
          value: "",
        },
        name: {
          rules: 1,
          value: "",
        },
        password: {
          rules: 1,
          value: "",
        },
        password2: {
          rules: 1,
          value: "",
        },
        passwordTips: {
          rules: 1,
          value: "",
        },
      },
    };
  },
  watch: {
    loginType() {
      this.$ShowMoney.noneMnemonic.setLoginType(this.loginType);
      this.Phonefrom = {
        phone: {
          rules: 1,
          value: "",
        },
        email: {
          rules: 1,
          value: "",
        },
        yzm: {
          rules: 1,
          value: "",
        },
        picyzm: {
          rules: 1,
          value: "",
        },
        name: {
          rules: 1,
          value: "",
        },
        password: {
          rules: 1,
          value: "",
        },
        password2: {
          rules: 1,
          value: "",
        },
        passwordTips: {
          rules: 1,
          value: "",
        },
      };
    },
    "$store.state.metaShow": function () {
      // console.log(this.$store)
      if (!this.$store.state.metaShow) {
        let userMeta = this.$store.state.userMeta;
        if (userMeta.showId && userMeta.infoTxId && userMeta.name) {
          if (this.$route.query.from) {
            window.location.href = this.$route.query.from + "?type=registered";
          } else {
          }
        } else {
          return this.$message.error(this.$t("metaTxt"));
        }
      }
    },
    // "Phonefrom.password2.value": function () {
    //   this.checkPasswordLength();
    // },
    // "Phonefrom.password.value": function () {
    //   this.checkPasswordLength();
    // },
  },
  methods: {
    handleCountryChange(item) {
      console.log(item);
      this.phoneCode.code = item.iso2;
      this.phoneCode.dialCode = item.dialCode;
    },
    // 获取电话区号
    GetPhone(item) {
      this.phoneCode = item;
    },
    alert(item) {
      window.alert(item);
    },
    selectChange(item) {
      console.log(item);
    },
    // 获取焦点，class 的变化
    getBlur(type) {
      if (!this.Phonefrom[type].value) {
        this.Phonefrom[type].rules = 3;
      } else {
        this.Phonefrom[type].rules = 1;
      }
      if (type === "phone" && !isVerifiedPhone(this.Phonefrom.phone.value)) {
        this.$message.error(this.$t("phoneErr"));
      } else if (
        type === "email" &&
        !isVerifiedEmail(this.Phonefrom.phone.value)
      ) {
        this.$message.error(this.$t("emailErr"));
      }
    },
    getPicCode() {
      getImgCode({ characteristic: this.cert }).then((res) => {
        this.cert = res.data.characteristic;
        this.picCode = "data:image/jpeg" + ";base64," + res.data.imageCode;
      });
    },
    checkPasswordLength() {
      if (this.Phonefrom.password2.value && this.Phonefrom.password.value) {
        if (this.Phonefrom.password2.value !== this.Phonefrom.password.value) {
          this.passwordShow = true;
        } else {
          this.passwordShow = false;
        }
      }
      console.log(
        "this.Phonefrom.password.value.length",
        this.Phonefrom.password.value.length
      );
      if (
        (this.Phonefrom.password.value &&
          this.Phonefrom.password.value.length < 6) ||
        this.Phonefrom.password.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        (this.Phonefrom.password2.value &&
          this.Phonefrom.password2.value.length < 6) ||
        this.Phonefrom.password2.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        this.Phonefrom.password.value &&
        this.Phonefrom.password.value.length >= 6 &&
        this.Phonefrom.password.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      if (
        this.Phonefrom.password2.value &&
        this.Phonefrom.password2.value.length >= 6 &&
        this.Phonefrom.password2.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      return this.passwordLengthShow && !this.passwordShow;
    },
    getfocus(type) {
      this.Phonefrom[type].rules = 2;
    },
    setPhoneNub() {
      let phone = this.Phonefrom.phone.value;
      if (this.phoneCode.dialCode != 86 && this.loginType === "phone") {
        phone = this.phoneCode.dialCode + this.Phonefrom.phone.value;
      }
      return phone;
    },
    getyzm() {
      if (!this.Phonefrom.phone.value) {
        return this.$message.error(this.$t("phoneTipes"));
      }
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (this.loginType === "email" && !re.test(this.Phonefrom.phone.value)) {
        return this.$message.error(this.$t("emaiErr"));
      }
      // if (!this.Phonefrom.name.value) {
      //   return this.$message.error('用户名不能为空')
      // }
      if (this.YZMBtn) {
        return this.$message.error(this.$t("plaeaseAgainTips"));
      }
      let phone =
        (this.phoneCode.dialCode != 86 && this.loginType === "phone"
          ? "+"
          : "") + this.setPhoneNub();
      this.yzmLoading = true;
      this.YZMBtn = true;
      let that = this;
      let params = {
        // type: 2,
        userType: this.loginType,
        name: this.Phonefrom.name.value,
        isEnglish: this.$i18n.locale !== "zh",
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      registerVerification(params)
        .then((data) => {
          // console.log(data)
          this.yzmType = true;
          // timeout()
          this.setYZMText();
          that.yzmLoading = false;
          that.$message({
            message: this.$t("codeSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          // this.YZMBtn = false;
          // this.yzmLoading = false;
          this.clearYZText();
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    setYZMText() {
      this.yzmText = this.timeNub + "s";
      if (this.timeNub > 0) {
        this.timeC = setTimeout(() => {
          --this.timeNub;
          this.yzmText = this.timeNub + "s";
          this.setYZMText();
        }, 1000);
      } else {
        this.YZMBtn = false;
        this.timeNub = 60;
        this.yzmText = this.$t("codeTips");
      }
    },
    clearYZText() {
      if (this.timeC) {
        clearTimeout(this.timeC);
      }
      this.YZMBtn = false;
      this.timeNub = 60;
      this.yzmText = this.$t("codeTips");
      this.yzmLoading = false;
    },
    async subPhone() {
      if (
        !this.Phonefrom.phone.value ||
        !this.Phonefrom.yzm.value ||
        !this.Phonefrom.name.value
      ) {
        this.subPhoneShow = false;
        if (!this.Phonefrom.name.value) {
          this.Phonefrom.name.rules = 3;
        }
        if (!this.Phonefrom.phone.value) {
          this.Phonefrom.phone.rules = 3;
        }
        if (!this.Phonefrom.yzm.value) {
          this.Phonefrom.yzm.rules = 3;
        }
        return;
      }
      if (!this.yzmType) {
        return this.$notify.error({
          message: this.$t("codeTips2"),
          type: "warning",
        });
      }
      if (this.subPhoneShow) {
        return this.$message.error(this.$t("discussWarning"));
      }
      if (!this.Phonefrom.yzm.value) {
        return (this.Phonefrom.yzm.rules = 3);
      }
      if (!this.Phonefrom.picyzm.value) {
        return (this.Phonefrom.picyzm.rules = 3);
      }
      if ((this.Phonefrom.yzm.value + "").length < 6) {
        return this.$message.error(this.$t("codenotcomplete"));
      }
      const referCode =
        this.invitationCode || process.env.VUE_APP_DEFAULT_REFERCODE;
      console.log("referCode", referCode);
      // try {
      //   const referInfo = await getReferrerInfo({
      //     inviteCode: referCode
      //   })
      //   let referrerId
      //   console.log('refer info', referInfo)
      //   if (referInfo.metaId) {
      //     referrerId = referInfo.metaId
      //   } else {
      //     this.subPhoneShow = false;
      //     this.subPhoneLoad = false;
      //     this.invitationCode = ''
      //     return this.$message.error('Get refer info error')
      //   }
      //   localStorage.setItem("referCode", referCode);
      //   localStorage.setItem("referrerId", referrerId);
      // } catch (error) {
      //   this.subPhoneShow = false;
      //   this.subPhoneLoad = false;
      //   this.invitationCode = ''
      //   return this.$message.error('Get refer info error')
      // }
      this.subPhoneShow = true;
      this.subPhoneLoad = true;
      let phone = this.setPhoneNub();
      let params = {
        type: 2,
        userType: this.loginType,
        name: this.Phonefrom.name.value,
        code: this.Phonefrom.yzm.value,
        imageCode: this.Phonefrom.picyzm.value,
        // promotion: this.invitationCode === '' ? this.invitationCode : '000000',
        characteristic: this.cert,
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      // console.log(params)
      registerCheck(params)
        .then(async (res) => {
          const result = res;
          this.did = result.did;
          this.pk2 = result.pk2;
          this.userData = result;
          this.userData.phone = this.loginType == "phone" ? phone : "";
          this.userData.email = this.loginType == "email" ? phone : "";
          this.signType = 2;
          this.userData.register = this.loginType;

          this.userData.headers = this.$ShowMoney.noneMnemonic.getHeader();
          this.subPhoneShow = false;
          this.subPhoneLoad = false;
        })
        .catch((err) => {
          this.subPhoneShow = false;
          this.subPhoneLoad = false;
          this.Phonefrom.yzm.rules = 3;
          if (err.message == "The graphic verification code is incorrect") {
            return this.$message.error(this.$t("graphicError"));
          }
          return this.$message.error(
            err?.message == "Incorect code" ? this.$t("yzmError") : err.message
          );
        });
      // let senduserParams = {
      //   userType: loginType,
      //   address,
      //   type: 2,
      //   xpub: wallet.getXpub(),
      //   remark: '',
      //   pubkey: wallet.cur_private_key.publicKey.toString()
      // }
      // let res = await setUserWalletInfo(senduserParams, type)
    },
    debounceLogin() {
      if (this.checkPasswordLength()) {
        return;
      }

      this.debounce(this.subPassword, 1000, true);
    },
    subPassword() {
      if (
        !this.Phonefrom.password.value ||
        !this.Phonefrom.password2.value ||
        !this.Phonefrom.passwordTips.value
      ) {
        this.subPasswordShow = false;
        this.subPasswordLoad = false;
        if (!this.Phonefrom.password.value) {
          return (this.Phonefrom.password.rules = 3);
        }
        if (!this.Phonefrom.password2.value) {
          return (this.Phonefrom.password2.rules = 3);
        }
        if (!this.Phonefrom.passwordTips.value) {
          return (this.Phonefrom.passwordTips.rules = 3);
        }
      }
      if (this.Phonefrom.password.value !== this.Phonefrom.password2.value) {
        this.passwordShow = true;
        this.subPasswordShow = false;
        this.subPasswordLoad = false;
        return this.$message.error(this.$t("passwordError"));
      }
      if (this.subPasswordShow) {
        this.subPasswordShow = false;
        this.subPasswordLoad = false;
        // return this.$message.error(this.$t('discussWarning'))
      }
      this.subPasswordLoad = true;
      this.subPasswordShow = true;
      let phone = this.setPhoneNub();
      // 创建密码提示
      let params = {
        userType: this.loginType,
        remark: this.Phonefrom.passwordTips.value,
        headers: {
          accessKey: this.userData.token,
          timestamp: Date.now(),
          userName: phone,
        },
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      re.test(phone)
        ? (this.userData.email = phone)
        : (this.userData.phone = phone);
      // this.userData.headers = this.$ShowMoney.noneMnemonic.getHeader();
      setTimeout(() => {
        let key = this.$ShowMoney.noneMnemonic.createMasterHdPrivateKey(
          phone,
          this.Phonefrom.password.value,
          this.pk2,
          this.userData.tag
        );

        // console.log(key)
        // 实例化钱包
        let that = this;
        let accessKey = this.$utils.encrypt(
          this.Phonefrom.password.value,
          this.pk2.slice(0, 32)
        );
        // console.log(accessKey)
        // console.log(this.$utils.decrypt(accessKey, this.pk2.slice(0, 32)))
        // localStorage.setItem('loginShow', this.$store.state.loginShow)
        // localStorage.setItem('accessKey', accessKey)
        // if (this.$store.state.loginShow) {
        //   localStorage.setItem("accessKey", accessKey);
        //   // console.log('自动登录')
        // } else {
        //   // console.log('非自动登录')
        //   sessionStorage.setItem("accessKey", accessKey);
        // }
        localStorage.setItem("accessKey", accessKey);
        this.userData.loginType = this.loginType;
        // 注册时使用新路径10001
        this.userData.path = 10001;
        that.$store.state.userData = this.userData;
        const remark = this.Phonefrom.passwordTips.value;

        const de_mnemonic = this.$ShowMoney.noneMnemonic.decodeMnemonic(
          key.mnemonic,
          key.ppk
        );
        console.log("解码后的助记词：", de_mnemonic);
        const encrypt_mnemonic = this.$utils.aesEncryptMem(
          de_mnemonic,
          this.Phonefrom.password.value
        );
        const encrypt_password = this.$utils.encryptPassword(
          this.Phonefrom.password.value
        );
        this.$utils
          .newWallet(key, "sign", remark, encrypt_mnemonic, encrypt_password)
          .then(async (r) => {
            that.$forceUpdate();
            that.$cookie.set(
              "userData",
              true,
              60 * 60 * 24 * 30,
              null,
              null,
              true,
              "None"
            );
            // TODO:bug
            this.$store.state.metanet = r;
            console.log(that.$store.state.userData);
            localStorage.setItem("loginType", that.loginType);
            localStorage.setItem(
              "localuserData",
              JSON.stringify(that.userData)
            );
            console.log(process.env.VUE_APP_NETWORK);

            // if (useTestNet()) {
            if (true) {
              this.$store.state.metanet
                .initMetaId({
                  isMetasv: true,
                  onSuccess: (res) => {
                    // console.log(res)
                    that.$store.state.userData.metaId = res.metaId;
                    that.$store.state.userMeta = res;
                    that.$store.state.infoTxId = res.infoTxId;
                    localStorage.setItem(
                      "localuserData",
                      JSON.stringify(that.$store.state.userData)
                    );
                    that.gotoUrl("index");
                  },
                })
                .catch((error) => {
                  return this.$message.error(
                    error?.data?.message
                      ? this.$t("Error") + error.data.message
                      : "initmetaid error"
                  );
                });
              setTimeout(() => {
                // console.log('60S 了')
                this.$store.state.metaShow = false;
                this.subPasswordShow = false;
                this.subPasswordLoad = false;
                // let userMeta = this.$store.state.userMeta
              }, 60000);
            } else {
              // 不再自动订阅
              // const isRegister =
              //   await this.$store.state.metanet.registerMetasvXpub();
              // if (isRegister) {
              //   that.xupbInterval = setInterval(async () => {
              //     const xpubstate =
              //       await that.$store.state.metanet.getMetaSvXpubState();
              //     if (xpubstate) {
              //       clearInterval(that.xupbInterval);
              //       await this.$store.state.metanet.checkXpub();
              //       that.gotoUrl("index");
              //     }
              //   }, 1200);
              // } else {
              //   return this.$message.error("registerMetasvXpub error");
              // }
              that.gotoUrl("index");
              setTimeout(() => {
                // console.log('60S 了')
                this.$store.state.metaShow = false;
                this.subPasswordShow = false;
                this.subPasswordLoad = false;
                // let userMeta = this.$store.state.userMeta
              }, 240000);
            }
          })
          .catch((err) => {
            this.subPasswordShow = false;
            this.subPasswordLoad = false;
            return this.$message.error(
              this.$t("Error") + err?.message || err?.msg || err
            );
          });
      }, 500);
    },
    getReferrerInfo(code) {
      if (!code || code === "") return;
      getReferrerInfo({
        inviteCode: code,
      })
        .then((res) => {
          if (res.metaId) {
            this.referrerId = res.metaId;
          } else {
            this.message.error("Invalid referCode");
            this.invitationCode = "";
          }
        })
        .catch((error) => {
          this.invitationCode = "";
          this.$message.error(error.message);
        });
    },
  },
  destroyed() {
    clearInterval(this.timeC);
  },
  created() {
    this.getPicCode();
    const invitationCode = this.$route.query.refCode;
    if (invitationCode && this.$store.state.chain !== "MVC") {
      this.invitationCode = invitationCode;
      localStorage.setItem("referCode", invitationCode);
      this.getReferrerInfo(invitationCode);
    }
    // console.log(this.$route.query.from)
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/intl.css";
.login_box {
  background: url("../assets/images/home_bg.png");
  width: 100vw;
  background-size: cover;
  background-position: 50%;
  min-height: calc(100vh - 68px);
}
.login {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 7vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.login_left {
  padding-left: 20px;
  h2 {
    margin-top: 22px;
    font-size: 45px;
    color: #fff;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #bfc2cc;
    font-weight: normal;
  }
  p {
    font-size: 14px;
    color: #bfc2cc;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    i {
      width: 8px;
      height: 8px;
      background: rgba(234, 179, 0, 1);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .app_downloads {
    display: flex;
    margin-top: 45px;
    a {
      width: 195px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 30px;
      margin-right: 22px;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
    }
  }
}
.login_right {
  padding: 20px;
}
::v-deep .vue-country-intl .country-intl-input-wrap {
  border: unset;
}
::v-deep .vue-country-intl .country-intl-label {
  padding-left: 0;
  padding: 7px 16px;
  font-size: 14px;
}
.mt-15 {
  margin-top: 15px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  border-radius: 17px;
  padding: 50px;
  .sign {
    display: flex;
    justify-content: space-between;
  }
  h6 {
    margin: 5px 0;
    font-size: 12px;
    color: #f56c6c;
  }
  h4 {
    margin-top: 20px;
    font-size: 16px;
  }
  p {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #303133;
  }
  h2 {
    color: #303133;
    font-size: 26px;
    margin-bottom: 22px;
    cursor: pointer;
    font-weight: normal;
    font-family: PingFang SC;
  }
  .blod {
    font-weight: bold;
  }
  .countries {
    height: 40px;
    font-size: 15px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 25px;
    height: 25px;
  }
  .picyzm {
    width: unset;
    height: unset;
  }
  .yzmShow {
    background: #eab300;
  }
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: 60%;
  }
  button {
    font-size: 12px;
    color: #f9f9f9;
    width: 96px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
.type_button {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span {
    cursor: pointer;
    color: #606266;
    font-size: 15px;
  }
}
.login_button {
  width: 270px;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
.login_button2 {
  width: 100%;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
}
.user_home {
  ::v-deep .el-dialog {
    max-width: 600px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .login_right {
    padding-bottom: 60px;
  }
  .login_button {
    margin-top: 45px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
  .user_home {
    ::v-deep .el-dialog {
      border-radius: 15px;
      width: 80%;
    }
  }
}
</style>
